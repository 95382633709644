@font-face {
  font-family: 'Philosopher';
  src: url('./assets/fonts/Philosopher-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Philosopher';
  src: url('./assets/fonts/Philosopher-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Philosopher';
  src: url('./assets/fonts/Philosopher-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Philosopher';
  src: url('./assets/fonts/Philosopher-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}