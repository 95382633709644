@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.css';

.name-highlight {
  font-weight: bold;
  font-size: 1.1em;
  color: #b4e1f6; /* Example color */
}

.link-gradient {
  background: linear-gradient(to bottom, #89CFF0, #c175f6);
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.link-gradient:hover {
  background: linear-gradient(to top, #d2f1ff, #ebcfff);
  -webkit-background-clip: text;
  color: transparent;
  text-decoration: underline;
  transition: color 0.3s ease;
}



body {
  font-family: 'Philosopher', sans-serif;
}
.backface-hidden {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.rotateY-180 {
  transform: rotateY(180deg);
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* button animation */

.glossy-button {
  position: relative;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;
  overflow: hidden;
}

.glossy-button img {
  margin-right: 10px;
}

.glossy-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: inset 0 0 0 rgba(174, 162, 255, 0);
  transition: box-shadow 0.3s ease;
}

/* Keyframes for the hover effect */
@keyframes hoverAnimation {
  0%, 100% {
    transform: scale(1);
    box-shadow: inset 0 0 0 rgba(174, 162, 255, 0);
  }
  50% {
    box-shadow: inset 0 0 20px rgba(174, 162, 255, 0.6);
    transform: scale(1.03);
  }
}

/* Animation class */
.hover-animate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: inset 0 0 0 rgba(174, 162, 255, 0);
  animation: hoverAnimation 4s infinite;
}

.hover-animate-1 {
  animation-delay: 0s;
}

.hover-animate-2 {
  animation-delay: 0.4s;
}

.hover-animate-3 {
  animation-delay: 0.8s;
}

.glossy-button:hover {
  transform: scale(1.05);
}

.glossy-button:active {
  transform: scale(0.95);
}